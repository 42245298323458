var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Pane'),_c('a-card',{staticClass:"container"},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 8 },"wrapper-col":{ span: 12 },"colon":false},on:{"submit":_vm.handleSubmit}},[_c('a-row',[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"编码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'deptCode',
                {
                  initialValue: _vm.detail.deptCode,
                  rules: [
                    {
                      required: true,
                      message: '请输入！',
                    },
                  ],
                },
              ]),expression:"[\n                'deptCode',\n                {\n                  initialValue: detail.deptCode,\n                  rules: [\n                    {\n                      required: true,\n                      message: '请输入！',\n                    },\n                  ],\n                },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'uniqueName',
                {
                  initialValue: _vm.detail.uniqueName,

                  rules: [
                    {
                      required: true,
                      message: '请输入！',
                    },
                  ],
                },
              ]),expression:"[\n                'uniqueName',\n                {\n                  initialValue: detail.uniqueName,\n\n                  rules: [\n                    {\n                      required: true,\n                      message: '请输入！',\n                    },\n                  ],\n                },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"类型"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'type',
                {
                  initialValue: _vm.detail.type,
                  rules: [{ required: true, message: '请选择！' }],
                },
              ]),expression:"[\n                'type',\n                {\n                  initialValue: detail.type,\n                  rules: [{ required: true, message: '请选择！' }],\n                },\n              ]"}]},_vm._l((_vm.typeList),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"性质"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'property',
                {
                  initialValue: _vm.detail.property,
                  rules: [{ required: true, message: '请选择！' }],
                },
              ]),expression:"[\n                'property',\n                {\n                  initialValue: detail.property,\n                  rules: [{ required: true, message: '请选择！' }],\n                },\n              ]"}]},_vm._l((_vm.orgProperty),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"定编人数"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'staffing',
                {
                  initialValue: _vm.detail.staffing,
                  rules: [{ required: true, message: '请输入！' }],
                },
              ]),expression:"[\n                'staffing',\n                {\n                  initialValue: detail.staffing,\n                  rules: [{ required: true, message: '请输入！' }],\n                },\n              ]"}],staticStyle:{"width":"100%"}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"状态"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'status',
                {
                  initialValue: String(_vm.detail.status),
                  rules: [{ required: true, message: '请选择！' }],
                },
              ]),expression:"[\n                'status',\n                {\n                  initialValue: String(detail.status),\n                  rules: [{ required: true, message: '请选择！' }],\n                },\n              ]"}]},_vm._l((_vm.statusList),function(item){return _c('a-radio',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',[_c('span',{staticClass:"required",attrs:{"slot":"label"},slot:"label"},[_vm._v("负责人")]),_c('div',[_c('EmployeeSelector',{attrs:{"value":_vm.master2.name ? [_vm.master2] : []},on:{"change":(arr) => {
                    if (arr.length > 0) {
                      _vm.master2 = arr[0];
                    } else {
                      _vm.master2 = {};
                    }
                  }}},[_c('a-button',{staticStyle:{"text-align":"left"},attrs:{"block":""}},[(_vm.master2.name)?_c('div',[_vm._v(" "+_vm._s(_vm.master2.name)+" ")]):_c('div',[_vm._v(" ")])])],1)],1)])],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"分管领导"}},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v("分管领导")]),_c('div',[_c('EmployeeSelector',{attrs:{"value":_vm.chargerObj.name ? [_vm.chargerObj] : []},on:{"change":(arr) => {
                    if (arr.length > 0) {
                      _vm.chargerObj = arr[0];
                    } else {
                      _vm.chargerObj = {};
                    }
                  }}},[_c('a-button',{staticStyle:{"text-align":"left"},attrs:{"block":""}},[(_vm.chargerObj.name)?_c('div',[_vm._v(" "+_vm._s(_vm.chargerObj.name)+" ")]):_c('div',[_vm._v(" ")])])],1)],1)])],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"负责人(副)","label-col":{ span: 4 },"wrapper-col":{ span: 18 }}},[_c('div',[_c('EmployeeSelector',{attrs:{"single":false,"value":_vm.master1List.length > 0 ? _vm.master1List : []},on:{"change":(arr) => {
                    if (arr.length > 0) {
                      _vm.master1List = arr;
                    } else {
                      _vm.master1List = [];
                    }
                  }}},[_c('a-button',{staticStyle:{"text-align":"left"},attrs:{"block":""}},[(_vm.master1List.length > 0)?_c('div',[_vm._v(" "+_vm._s(_vm.master1List.map((item) => item.name).join(", "))+" ")]):_c('div',[_c('span',{staticClass:"placeholder"},[_vm._v("可多选")])])])],1)],1)])],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"所属组织","label-col":{ span: 4 },"wrapper-col":{ span: 18 }}},[_c('a-cascader',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'fatherList',
                {
                  rules: [{ required: true, message: '请选择！' }],
                },
              ]),expression:"[\n                'fatherList',\n                {\n                  rules: [{ required: true, message: '请选择！' }],\n                },\n              ]"}],attrs:{"placeholder":"","changeOnSelect":"","options":_vm.list,"fieldNames":{
                label: 'uniqueName',
                value: 'id',
                children: 'orgs',
              }}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"部门职能","label-col":{ span: 4 },"wrapper-col":{ span: 18 }}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'remarks',
                {
                  initialValue: _vm.detail.remarks,
                },
              ]),expression:"[\n                'remarks',\n                {\n                  initialValue: detail.remarks,\n                },\n              ]"}],attrs:{"auto-size":{ minRows: 3 }}})],1)],1)],1),_c('div',{staticClass:"center"},[_c('a-space',[_c('a-button',{attrs:{"htmlType":"submit","type":"primary","loading":_vm.saveLoading}},[_vm._v("保存")]),_c('a-button',{on:{"click":function($event){return _vm.$close(_vm.$route.path)}}},[_vm._v("关闭")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }