<template>
  <div>
    <Pane />

    <a-card class="container">
      <a-form
        :form="form"
        @submit="handleSubmit"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 12 }"
        :colon="false"
      >
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="编码">
              <a-input
                v-decorator="[
                  'deptCode',
                  {
                    initialValue: detail.deptCode,
                    rules: [
                      {
                        required: true,
                        message: '请输入！',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="名称">
              <a-input
                v-decorator="[
                  'uniqueName',
                  {
                    initialValue: detail.uniqueName,

                    rules: [
                      {
                        required: true,
                        message: '请输入！',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="类型">
              <a-select
                v-decorator="[
                  'type',
                  {
                    initialValue: detail.type,
                    rules: [{ required: true, message: '请选择！' }],
                  },
                ]"
              >
                <a-select-option
                  v-for="item in typeList"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="性质">
              <a-select
                v-decorator="[
                  'property',
                  {
                    initialValue: detail.property,
                    rules: [{ required: true, message: '请选择！' }],
                  },
                ]"
              >
                <a-select-option
                  v-for="item in orgProperty"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="定编人数">
              <a-input-number
                style="width: 100%"
                v-decorator="[
                  'staffing',
                  {
                    initialValue: detail.staffing,
                    rules: [{ required: true, message: '请输入！' }],
                  },
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="状态">
              <a-radio-group
                v-decorator="[
                  'status',
                  {
                    initialValue: String(detail.status),
                    rules: [{ required: true, message: '请选择！' }],
                  },
                ]"
              >
                <a-radio
                  v-for="item in statusList"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}</a-radio
                >
              </a-radio-group>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item>
              <span slot="label" class="required">负责人</span>
              <div>
                <EmployeeSelector
                  @change="
                    (arr) => {
                      if (arr.length > 0) {
                        master2 = arr[0];
                      } else {
                        master2 = {};
                      }
                    }
                  "
                  :value="master2.name ? [master2] : []"
                >
                  <a-button block style="text-align: left">
                    <div v-if="master2.name">
                      {{ master2.name }}
                    </div>
                    <div v-else>&nbsp;</div>
                  </a-button>
                </EmployeeSelector>
              </div>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="分管领导">
              <span slot="label" class="">分管领导</span>

              <div>
                <EmployeeSelector
                  @change="
                    (arr) => {
                      if (arr.length > 0) {
                        chargerObj = arr[0];
                      } else {
                        chargerObj = {};
                      }
                    }
                  "
                  :value="chargerObj.name ? [chargerObj] : []"
                >
                  <a-button block style="text-align: left">
                    <div v-if="chargerObj.name">
                      {{ chargerObj.name }}
                    </div>
                    <div v-else>&nbsp;</div>
                  </a-button>
                </EmployeeSelector>
              </div>
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item
              label="负责人(副)"
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 18 }"
            >
              <div>
                <EmployeeSelector
                  :single="false"
                  @change="
                    (arr) => {
                      if (arr.length > 0) {
                        master1List = arr;
                      } else {
                        master1List = [];
                      }
                    }
                  "
                  :value="master1List.length > 0 ? master1List : []"
                >
                  <a-button block style="text-align: left">
                    <div v-if="master1List.length > 0">
                      {{ master1List.map((item) => item.name).join(", ") }}
                    </div>
                    <div v-else>
                      <span class="placeholder">可多选</span>
                    </div>
                  </a-button>
                </EmployeeSelector>
              </div>
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item
              label="所属组织"
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 18 }"
            >
              <a-cascader
                placeholder=""
                changeOnSelect
                v-decorator="[
                  'fatherList',
                  {
                    rules: [{ required: true, message: '请选择！' }],
                  },
                ]"
                :options="list"
                :fieldNames="{
                  label: 'uniqueName',
                  value: 'id',
                  children: 'orgs',
                }"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item
              label="部门职能"
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 18 }"
            >
              <a-textarea
                :auto-size="{ minRows: 3 }"
                v-decorator="[
                  'remarks',
                  {
                    initialValue: detail.remarks,
                  },
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <div class="center">
          <a-space>
            <a-button htmlType="submit" type="primary" :loading="saveLoading"
              >保存</a-button
            >
            <a-button @click="$close($route.path)">关闭</a-button>
          </a-space>
        </div>
      </a-form>
    </a-card>
  </div>
</template>

<script>
import EmployeeSelector from "@/components/employee-selector";
import { edit, fetchDetailTree } from "@/api/setting/organization";
import { mapGetters, mapState, mapActions } from "vuex";
export default {
  name: "humanResourcesOrgEdit",

  components: {
    EmployeeSelector,
  },

  data() {
    return {
      form: this.$form.createForm(this),
      master1List: [],
      master2: {},
      chargerObj: {},

      detail: {},
      saveLoading: false,
    };
  },

  computed: {
    ...mapState("humanResources", ["list"]),
    ...mapGetters("setting", ["findDataDict"]),
    statusList() {
      return this.findDataDict("status");
    },
    typeList() {
      return this.findDataDict("orgType");
    },
    orgProperty() {
      return this.findDataDict("orgProperty");
    },
  },

  mounted() {
    if (this.list.length === 0) {
      this.getList();
    }
  },

  activated() {
    const { query } = this.$route;
    const { id } = query || {};

    if (id && id !== this.id) {
      this.id = id;
      fetchDetailTree({
        uuid: id,
      }).then((res) => {
        if (res) {
          this.detail = res;
        }
        this.master2 = {
          userId: res.master2,
          name: res.master2Name,
        };
        this.chargerObj = {
          userId: res.charger,
          name: res.chargerName,
        };
        if (
          typeof res.master1 === "string" &&
          typeof res.master1Name === "string"
        ) {
          const arr = res.master1.split(",").map((item) => {
            return {
              userId: item,
            };
          });
          res.master1Name.split(",").forEach((item, index) => {
            arr[index].name = item;
          });
          this.master1List = arr;
        }

        if (typeof res.fullId === "string") {
          const arr = res.fullId.split(".");
          this.form.setFieldsValue({
            fatherList: arr.splice(0, arr.length - 1),
          });
        }
      });
    }
  },

  methods: {
    ...mapActions("humanResources", ["getList"]),

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          if (!this.master2.name) {
            this.$message.error("请选择负责人！");
            return;
          }

          let master1, master1Name;
          if (this.master1List.length > 0) {
            master1 = this.master1List.map((item) => item.userId).join();
            master1Name = this.master1List.map((item) => item.name).join();
          }

          this.saveLoading = true;

          edit({
            ...this.detail,
            ...values,
            fatherList: undefined,
            name: values.uniqueName,
            fullId: values.fatherList.join(".") + "." + this.detail.id,
            parentId: values.fatherList[values.fatherList.length - 1],

            master2: this.master2.userId,
            master2Name: this.master2.name,
            charger: this.chargerObj.userId,
            chargerName: this.chargerObj.name,
            master1,
            master1Name,
          })
            .then(() => {
              this.getList();
              this.$close(this.$route.path);
            })
            .finally(() => {
              this.saveLoading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  padding: 24px 160px;
}

.center {
  margin-top: 80px;
}
</style>
